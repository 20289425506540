import Typed from 'typed.js'
import SimpleMaskMoney from 'simple-mask-money'
import $ from 'jquery'
import 'jquery-mask-plugin'
import axios from 'axios'

const options = {
  strings: ["IPVA", "Carro", "Imóvel", "Escola", "Apartam^800ento", "Moto", "Aluguel", "Boleto"],
  typeSpeed: 120,
  loop: true,
  loopCount: Infinity
}

const args = {
  allowNegative: false,
  negativeSignAfter: false,
  prefix: 'R$ ',
  suffix: '',
  fixed: true,
  fractionDigits: 2,
  decimalSeparator: ',',
  thousandsSeparator: '.',
  cursor: 'move'
}

// const API = 'http://localhost:3000'
const API = 'https://us-central1-atrasadoapp.cloudfunctions.net/api'
const MIN_VALUE = 10000
const MAX_VALUE = 1000000

let formErrors: Array<string> = []
function formValidate(): boolean {
  resetFormErrors()

  let amountEl = $('#lbAmount')
  let parcelEl = $('#parcel')
  let emailEl = $('#lbEmail')
  let phoneEl = $('#lbPhone')
  let termsEl = $('#lbTerms')
  let nameEl = $('#lbName')

  let amount: number = amountEl.find('input').val()?.replace(/\D/g, "")
  if (amount < MIN_VALUE) {
    formErrors.push('Informe um valor maior que R$ 100,00')
    $(amountEl).append('<span class="elError">Informe um valor maior que R$ 100,00</span>')
    amountEl.addClass('error')
  }

  if (amount > MAX_VALUE) {
    formErrors.push('Informe um valor abaixo de R$ 10.000,00')
    $(amountEl).append('<span class="elError">Informe um valor abaixo de R$ 10.000,00</span>')
    amountEl.addClass('error')
  }

  let email: string = emailEl.find('input').val()
  if (!validateEmail(email)) {
    formErrors.push('Ops... Esse não parece um email válido')
    $(emailEl).append('<span class="elError">Ops... Esse não parece um email válido</span>')
    emailEl.addClass('error')
  }

  let phone: string = phoneEl.find('input').val()
  if (!validatePhone(phone)) {
    formErrors.push('Ops... Esse não parece um celular válido')
    $(phoneEl).append('<span class="elError">Ops... Esse não parece um celular válido</span>')
    phoneEl.addClass('error')
  }

  let name: string = nameEl.find('input').val()
  if (!validateName(name)) {
    formErrors.push('Ops... Esse não parece um Nome completo')
    $(nameEl).append('<span class="elError">Ops... Esse não parece um Nome completo</span>')
    nameEl.addClass('error')
  }

  let terms: boolean = termsEl.find('input').prop('checked')
  if (!terms) {
    formErrors.push('Você precisa concordar com nossos termos de uso')
    termsEl.addClass('error')
  }

  return formErrors.length === 0
}
var resetFormErrors = () => {
  formErrors = []
  $('.elError').remove()
  $('label.error').removeClass('error')
}

var simular = () => {
  if (!formValidate()) return
  let simulacao = {
    amount: $('#amount').val().replace("R$ ","").replace(".","").replace(",","."),
    parcel: $('#parcel').val(),
    email: $('#email').val(),
    phone: $('#phone').val(),
    name: $('#name').val(),
    whatsapp: $('#whatsapp').prop('checked'),
    terms: $('#terms').prop('checked')
  }

  axios.post(`${API}/simulator`, simulacao)
  .then((response) => {
    console.log(response)
  })
  .catch((error) => {
    console.log(error)
  })
}

function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validatePhone(phone: string): boolean {
  var regex = new RegExp('^\\(?[1-9]{2}\\)? ?(?:[2-8]|9[1-9])[0-9]{3}\\-?[0-9]{4}$')
  return regex.test(phone);
}

function validateName(name: string): boolean {
  return name !== ""
  // const regex = new RegExp('^([\w]{2,})+\s+([\w\s]{3,})+$')
  // return regex.test(name)
}

$(document).ready(() => {
  new Typed('#credito', options);
  SimpleMaskMoney.setMask('#amount', args)
  $('#phone').mask('(00) 00000-0000')

  $('#simular').click(simular)
});